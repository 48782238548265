
//公共库
import { Form } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { parseTime, getPwdErrMsg } from "@/utils";
import { isValidPassword } from "@/utils/validate";
import Pagination from "@/comp/Pagination/index.vue";
import { getUsers, updateUser, createUser, shutDownUser } from "@/api/request/admin";

//组件
@Component({
  name: "Admin",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //列表请求参数
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //筛选数据
    type: undefined,
    realmname: undefined,
  };

  //定义变量
  private list: any[] = []; //列表数据
  private total: number = 0; //数据总量
  private listRole: any[] = []; //规则数据
  private listLoading: boolean = true; //显示加载

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取管理员列表
  private async getList() {
    //显示加载界面
    this.listLoading = true;

    //获取数据
    const { data } = await getUsers(this.listQuery);

    //数据赋值
    this.total = data.total; //总数
    this.list = data.admin_list; //管理员列表
    this.listRole = data.role_list; //角色列表

    //隐藏加载界面
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //基本信息
      u_id: 0, //账号ID
      u_name: "", //账号
      u_pass: "", // 密码
      u_nickname: "", //昵称

      //状态信息
      u_status: 0, //状态 1-正常 3-禁止登录
      u_created_time: 0, //创建角色时间
      u_role_id: undefined, //账号类型 1-总管理员 2-客服

      //登录信息
      u_last_ip: "", //登录IP
      u_last_time: 0, //最后登录时间
    };
    this.dialogStatus = "create";

    //显示创建
    this.dialogVisible = true;

    //清除验证
    this.$nextTick(() => {
      // 移除校验结果
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  //处理搜索
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //是否是创建管理员
  private isCreateRole(): boolean {
    return this.dialogStatus === "create";
  }

  //处理封号|解封
  private async handleBan(row: any) {
    //显示等待
    this.listLoading = true;

    //发起请求
    await shutDownUser({ u_id: row.u_id, u_status: row.u_status === 1 ? 3 : 1 });

    //显示提示
    this.$notify({
      title: "成功",
      duration: 2000,
      type: "success",
      message: "更新成功",
    });

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.dialogStatus = "update";
    this.addForm = Object.assign({}, row);
    this.addForm.u_pass = "";

    //显示界面
    this.dialogVisible = true;

    //清除验证
    this.$nextTick(() => {
      // 移除校验结果
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  //获取时间
  private parseTime(time: any): string {
    return parseTime(time) as string;
  }

  //获取权限列表
  private getRoleList(type: any): any[] {
    //数据赋值
    const isSuperRole: boolean = this.isSuperRole(type);

    //超级管理员
    if (isSuperRole) {
      //返回角色列表
      return this.listRole;
    }
    //普通账号
    else {
      //定义变量
      const ls: any[] = [];

      //数据赋值
      for (let i = 0; i < this.listRole.length; i++) {
        //数据赋值
        const item = this.listRole[i];

        //排除超级管理员
        if (item.perm_config !== "*") {
          ls.push(item);
        }
      }

      //返回数据
      return ls;
    }
  }

  //账号类型名称
  private getUserType(type: any): string {
    //定义变量
    let roleName: string = "未知";

    //数据赋值
    if (this.listRole.length > 0) {
      for (let i = 0; i < this.listRole.length; i++) {
        const item = this.listRole[i];
        if (item.id === type) {
          roleName = item.role_name;
          break;
        }
      }
    }

    //返回角色名
    return roleName;
  }

  //是否为超级管理员
  private isSuperRole(type: any): boolean {
    //定义变量
    let isSuper: boolean = false;

    //数据赋值
    for (let i = 0; i < this.listRole.length; i++) {
      const item = this.listRole[i];
      if (item.id === type && item.perm_config === "*") {
        isSuper = true;
      }
    }

    //返回结果
    return isSuper;
  }

  //是否可选
  private disableChose(type: any): boolean {
    return type && !this.isCreateRole() ? this.isSuperRole(type) : false;
  }

  //验证角色
  private validateRole(rule: any, value: string, callback: Function): void {
    //未选择
    if (value == undefined) {
      callback(new Error("请选择账号类型"));
    }
    //不允许添加超级管理员
    else if (this.isSuperRole(value) && this.isCreateRole()) {
      callback(new Error("不允许添加超级管理员"));
    }
    //有效角色
    else if (Number(value) === 0) {
      callback(new Error("请选择有效的账号类型"));
    }
    //回调函数
    else {
      callback();
    }
  }

  //验证密码
  private validatePassword(rule: any, value: string, callback: Function): void {
    //验证密码
    const vType: number = isValidPassword(value);

    //验证失败
    if (vType !== 1) {
      //获取提示
      const msg = getPwdErrMsg(vType);

      //回调函数
      callback(new Error(msg as any));
    }
    //验证成功
    else {
      callback();
    }
  }

  //--------------------------------------- 编辑/添加用户 ---------------------------------------
  //表单规则
  private rules: any = {
    //昵称
    u_nickname: [{ required: true, message: "昵称不能为空", trigger: "change" }],

    //用户名
    u_name: [{ required: true, message: "用户名不能为空", trigger: "change" }],

    //账号类型
    u_role_id: [{ required: true, validator: this.validateRole, trigger: "change" }],

    //账号密码
    u_pass: [{ required: true, validator: this.validatePassword, trigger: "change" }],
  };

  //添加表单
  private addForm: any = {
    //基本信息
    u_id: 0, //账号ID
    u_name: "", //账号
    u_pass: "", // 密码
    u_nickname: "", //昵称

    //状态信息
    u_status: 0, //状态 1-正常 3-禁止登录
    u_role_id: 0, //账号类型 1-总管理员 2-客服
    u_created_time: 0, //创建角色时间

    //登录信息
    u_last_ip: "", //登录IP
    u_last_time: 0, //最后登录时间
  };

  //定义变量
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //显示对话框
  private titleMap = { update: "编辑用户", create: "添加用户" }; //标题数据

  //创建用户
  private createData(): void {
    //验证表单
    (this.$refs.dataForm as Form).validate(async (valid) => {
      console.log(valid);
      //成功
      if (valid) {
        //数据赋值
        this.addForm.u_status = 1; //状态

        //创建用户
        await createUser(this.addForm);

        //隐藏界面
        this.dialogVisible = false;

        //获取数据
        this.getList();

        //显示提示
        this.$notify({
          title: "成功",
          duration: 2000,
          type: "success",
          message: "创建成功",
        });
      }
    });
  }

  //更新用户信息
  private updateData(): void {
    //验证表单
    (this.$refs.dataForm as Form).validate(async (valid) => {
      //成功
      if (valid) {
        //更新用户
        await updateUser(this.addForm);

        //隐藏界面
        this.dialogVisible = false;

        //获取数据
        this.getList();

        //显示提示
        this.$notify({
          title: "成功",
          duration: 2000,
          type: "success",
          message: "更新成功",
        });
      }
    });
  }
}
