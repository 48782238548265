//本地导入
import request from "../request";

//获取管理员列表
export const getUsers = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/get",
  });

//更新用户信息
export const updateUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/update",
  });

//创建用户
export const createUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/add",
  });

//账号封停|解封
export const shutDownUser = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/shutDown",
  });

//角色设置
export const setPermissionRole = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/setRole",
  });

//获取角色
export const getPermissionRole = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/getRole",
  });

//删除角色
export const delPermissionRole = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/delRole",
  });
